(function(localStorage) {

    let intervalID;
    let timeoutID;

    const _setBgTransition = () => {
        clearInterval(intervalID);
        clearTimeout(timeoutID);

        const localTheme = localStorage.getItem('keps_theme') || 'chill';
        if(localTheme !== 'chill' && localTheme !== 'acide') return;

        const root = document.querySelector(':root');
        const rs = getComputedStyle(root);
        const acid = document.querySelector('.theme_acide');

        // Récupération de la variable css pour la transition et passage de s -> ms
        let transition_delay;
        let preset_number = parseInt(rs.getPropertyValue('--preset-number'));

        switch (localTheme) {
            case "chill":
                transition_delay = parseInt(rs.getPropertyValue('--transition-background').replace('s', '')) * 1000;
                break;
            default:
                transition_delay = parseInt(getComputedStyle(acid).getPropertyValue('--transition-background').replace('s', '')) * 1000;
                break;
        }

        const delay = transition_delay * 1.5;

        // Pseudo element affiché en premier
        let el = "before"

        // Les presets 1 et 2 sont chargés par défaut
        let next_preset = 3;
        intervalID = setInterval(() => {
            if(el === 'before') {
                root.style.setProperty('--opacity-after-bg', '1')
                root.style.setProperty('--opacity-before-bg', '0')
            } else {
                root.style.setProperty('--opacity-before-bg', '1')
                root.style.setProperty('--opacity-after-bg', '0')
            }
            timeoutID = setTimeout( async () => {
                root.style.setProperty('--current-'+ el +'-gradient-bg', `var(--preset-gradient-background-${next_preset})`);
                next_preset = (next_preset + 1) > preset_number ? 1 : next_preset + 1;
                el = el === "after" ? "before" : "after";
            }, transition_delay);

        }, delay);
    }
    _setBgTransition();

    document.addEventListener('change', function (e) {
        const input = e.target.closest('input.keps_theme');
        if (!input || !input.checked) return;
        _setBgTransition();
    });
})(window.localStorage)